import React from 'react'
import { Link } from 'gatsby'

import FreePlanIcon from '../../icons/plan-free.svg'
import StarterPlanIcon from '../../icons/plan-starter.svg'
import CheckIcon from '../../icons/icon-check.svg'
import ButtonIcon from '../../icons/arrow-forward.svg'

import { useTranslation } from 'gatsby-plugin-react-i18next'

const PricingSection = () => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-wrap items-center justify-center -mx-4 mt-12 lg:mt-24'>
      <div className='w-full md:w-1/3 lg:w-1/3 px-4 lg:my-6'>
        <div className='border-solid border-4 border-white px-8 py-8 mb-8'>
          <h3 className='text-2xl font-display font-black text-center mb-4'>
            {t('pricing_tables.pricing_table_01.name')}
          </h3>
          <div className='flex justify-center'>
            <FreePlanIcon />
          </div>
          <div className='mt-8 pt-7 text-center font-display font-black text-3xl lg:text-4xl'>
            {t('pricing_tables.pricing_table_01.price')}
            <span className='font-semibold text-sm'> {t('pricing_tables.pricing_table_01.per')}</span>
          </div>
          <p className='text-center mt-2'>
            {t('pricing_tables.pricing_table_01.desc')}
          </p>
          <ul className='mt-6 mb-8 pb-4 flex flex-col items-center'>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_01.options.01')}</span>
            </li>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_01.options.02')}</span>
            </li>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_01.options.03')}</span>
            </li>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_01.options.04')}</span>
            </li>
          </ul>
          <div className='text-center'>
            <Link to='/' className='button button-outline hover:opacity-75'>
              <span>{t('pricing_tables.pricing_table_01.button')}</span>
              <ButtonIcon className='text-black fill-current ml-2' />
            </Link>
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/1 lg:w-1/3 px-4 lg:my-6'>
        <div className='bg-white border-solid border-4 border-black px-8 py-8 mb-8 relative'>
          <span className='inline-block px-4 py-1 uppercase bg-white text-black font-bold border-solid border-4 border-black absolute top-0 right-0 transform -translate-y-1/2 -mr-4'>
            Coming soon...
          </span>
          <h3 className='text-2xl font-display font-black text-center mb-4'>{t('pricing_tables.pricing_table_02.name')}</h3>
          <div className='flex justify-center'>
            <StarterPlanIcon />
          </div>
          <div className='mt-8 pt-7 text-center font-display font-black text-3xl lg:text-4xl'>
            {t('pricing_tables.pricing_table_02.price')}
            <span className='font-semibold text-sm'> {t('pricing_tables.pricing_table_02.per')}</span>
          </div>
          <p className='text-center mt-2'>
            {t('pricing_tables.pricing_table_02.desc')}
          </p>
          <ul className='mt-6 mb-8 pb-4 flex flex-col items-center'>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_02.options.01')}</span>
            </li>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_02.options.02')}</span>
            </li>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_02.options.03')}</span>
            </li>
            <li className='relative pl-8 mb-4'>
              <div className='w-8 h-8 absolute top-0 left-0 flex items-center justify-center'>
                <CheckIcon />
              </div>
              <span className='inline-block pl-2 pt-1'>{t('pricing_tables.pricing_table_02.options.04')}</span>
            </li>
          </ul>
          <div className='text-center'>
            <Link to='/' className='button button-outline hover:opacity-75'>
              <span>{t('pricing_tables.pricing_table_02.button')}</span>
              <ButtonIcon className='text-black fill-current ml-2' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingSection
