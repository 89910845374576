import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import SectionTitle from '../components/sectionTitle'
import FeatureBox from '../components/featureBox'
import BenefitsBox from '../components/benefitsBox'
import Pricing from '../components/sections/pricing'
import Testimonials from '../components/sections/testimonials'

import FeatureIcon01 from '../icons/feature-1.svg'
import FeatureIcon02 from '../icons/feature-2.svg'
import FeatureIcon03 from '../icons/feature-3.svg'
import FeatureIcon04 from '../icons/feature-4.svg'
import FeatureIcon05 from '../icons/feature-5.svg'
import FeatureIcon06 from '../icons/feature-6.svg'
import BenefitIllustration01 from '../images/benefit-1.svg'
import BenefitIllustration02 from '../images/benefit-2.svg'
import BenefitIllustration03 from '../images/benefit-3.svg'

import { useTranslation } from 'gatsby-plugin-react-i18next'

const IndexPage = (props) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('seo_title')} />
      <Hero
        title={t('hero.title')}
        lead={t('hero.lead')}
        button={t('hero.button')}
        image={props.data.heroImg.childImageSharp.fluid}
      />

      <section className='pt-8 md:pt-24' id='feature-section'>
        <div className='container mx-auto px-4'>
          <SectionTitle
            title={t('features_head')}
            subtitle={t('features_subhead')}
          />

          <div className='flex flex-wrap -mx-4'>
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 my-4 lg:my-8'>
              <FeatureBox
                title={t('features.feature_01.title')}
                lead={t('features.feature_01.lead')}
                icon={<FeatureIcon01 />}
              />
            </div>
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 my-4 lg:my-8'>
              <FeatureBox
                title={t('features.feature_02.title')}
                lead={t('features.feature_02.lead')}
                icon={<FeatureIcon02 />}
              />
            </div>
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 my-4 lg:my-8'>
              <FeatureBox
                title={t('features.feature_03.title')}
                lead={t('features.feature_03.lead')}
                icon={<FeatureIcon03 />}
              />
            </div>
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 my-4 lg:my-8'>
              <FeatureBox
                title={t('features.feature_04.title')}
                lead={t('features.feature_04.lead')}
                icon={<FeatureIcon04 />}
              />
            </div>
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 my-4 lg:my-8'>
              <FeatureBox
                title={t('features.feature_05.title')}
                lead={t('features.feature_05.lead')}
                icon={<FeatureIcon05 />}
              />
            </div>
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 my-4 lg:my-8'>
              <FeatureBox
                title={t('features.feature_06.title')}
                lead={t('features.feature_06.lead')}
                icon={<FeatureIcon06 />}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='pt-8' id='benefits-section'>
        <div className='container mx-auto px-4'>
          <BenefitsBox
            title={t('benefits.benefit_01.title')}
            lead={t('benefits.benefit_01.lead')}
            illustration={BenefitIllustration01}
          />
          <BenefitsBox
            title={t('benefits.benefit_02.title')}
            lead={t('benefits.benefit_02.lead')}
            illustration={BenefitIllustration02}
          />
          <BenefitsBox
            title={t('benefits.benefit_03.title')}
            lead={t('benefits.benefit_03.lead')}
            illustration={BenefitIllustration03}
          />
        </div>
      </section>

      <section className='py-8' id='pricing-section'>
        <div className='container mx-auto px-4'>
          <SectionTitle
            title={t('pricing_head')}
            subtitle={t('pricing_subhead')}
            classes='text-center lg:pt-8 lg:mt-8'
          />
          <Pricing />
        </div>
      </section>

      <section className='py-8'>
        <div className='container mx-auto'>
          <SectionTitle
            title={t('testimonials_head')}
            subtitle={t('testimonials_subhead')}
            classes='text-center lg:pt-8 lg:mt-8'
          />
          <Testimonials />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    heroImg: file(relativePath: { eq: "hero.png" }) {
      ...fluidImage
    }
  }
`
