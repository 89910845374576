import React from 'react'
import TestimonialPhoto from '../../images/testimonial.png'
import QuoteIcon from '../../icons/icon-quote.svg'
import Slider from 'react-slick'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 3000,
  fadeIn: false,
  autoplay: true,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1
}

const PricingSection = () => {
  const { t } = useTranslation()
  return (
    <Slider {...settings}>
      <div className='flex flex-wrap'>
        <div className='w-full lg:w-3/5 ml-auto mr-auto'>
          <div className='text-center rounded-xl px-8 py-8'>
            <QuoteIcon className='mb-4 inline-block' />
            <p className='italic text-l lg:text-xl'>
              {t('testimonials.01.text')}
            </p>
          </div>
          <div className='text-center'>
            <div className='inline-flex p-6 ml-auto mr-auto mb-8'>
              <img src={TestimonialPhoto} alt={t('testimonials.01.name')} className='w-12 h-12 round-full' width='48px' height='48px' />
              <div className='text-left ml-4'>
                <h2 className='text-lg font-bold'>{t('testimonials.01.name')}</h2>
                <div className='text-secondary'>{t('testimonials.01.position')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap'>
        <div className='w-full lg:w-3/5 ml-auto mr-auto'>
          <div className='text-center rounded-xl px-8 py-8'>
            <QuoteIcon className='mb-4 inline-block' />
            <p className='italic text-l lg:text-xl'>
              {t('testimonials.02.text')}
            </p>
          </div>
          <div className='text-center'>
            <div className='inline-flex p-6 ml-auto mr-auto mb-8'>
              <img src={TestimonialPhoto} alt={t('testimonials.01.name')} className='w-12 h-12 round-full' width='48px' height='48px' />
              <div className='text-left ml-4'>
                <h2 className='text-lg font-bold'>{t('testimonials.01.name')}</h2>
                <div className='text-secondary'>{t('testimonials.01.position')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  )
}

export default PricingSection
