import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import ButtonIcon from '../icons/icon-today.svg'

const Hero = ({ title, lead, button, image }) => {
  return (
    <section className='pt-6 md:pt-20'>
      <div className='container mx-auto'>
        <div className='flex flex-wrap items-center px-4'>
          <div className='w-full md:w-1/2'>
            <h1 className='text-4xl lg:text-7xl font-display font-black leading-none break-words mb-8'>{title}</h1>
            <p className='text-black pt-1'>{lead}</p>
            <a href='https://app.booknowapp.co/sign-up' className='transition duration-300 ease-in-out button hover:opacity-75 py-4 mt-8'>
              <ButtonIcon className='mr-2' />
              <span>{button}</span>
            </a>
          </div>
          <div className='w-full md:w-1/2 self-end'>
            <div className='lg:pl-8 min-w-full lg:text-right my-8 overflow-hidden -mx-4 md:-mx-0'>
              <div className='w-full/70 md:w-full transform -translate-x-1/5 md:translate-x-0'>
                <Img fluid={image} alt={title} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  button: PropTypes.string
}

Hero.defaultProps = {
  title: '',
  lead: '',
  button: ''
}

export default Hero
