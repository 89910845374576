import PropTypes from 'prop-types'
import React from 'react'

const BenefitsBox = ({ title, lead, illustration }) => {
  return (
    <div className='flex flex-wrap items-center -mx-4 py-4 my-4 lg:py-12 lg:my-12'>
      <div className='w-full md:w-1/2'>
        <div className='px-4 lg:text-center'>
          <img src={illustration} className='inline-block' alt={title} width='280px' height='230' />
        </div>
      </div>
      <div className='w-full lg:w-1/2'>
        <div className='px-4 lg:px-20'>
          <div className=''>
            <h4 className='text-3xl lg:text-4xl font-display font-black leading-tight mt-6 lg:mt-0 mb-3'>{title}</h4>
            <p>{lead}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

BenefitsBox.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string
}

BenefitsBox.defaultProps = {
  title: 'Title',
  lead: 'Lead'
}

export default BenefitsBox
