import PropTypes from 'prop-types'
import React from 'react'

const SectionTitle = ({ title, subtitle, classes }) => {
  return (
    <div className={classes}>
      <span className='text-sm font-bold tracking-wide uppercase'>{subtitle}</span>
      <h2 className='text-3xl md:text-5xl font-display font-black leading-none tracking-wide mb-8'>{title}</h2>
    </div>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleColor: PropTypes.string,
  classes: PropTypes.string
}

SectionTitle.defaultProps = {
  title: '',
  subtitle: '',
  classes: ''
}

export default SectionTitle
