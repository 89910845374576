import PropTypes from 'prop-types'
import React from 'react'

const FeatureBox = ({ title, lead, icon }) => {
  return (
    <div className='lg:py-8'>
      <div className=''>
        {icon}
      </div>
      <div className=''>
        <h3 className='text-xl md:text-2xl font-display font-black tracking-wider mt-2 lg:mt-6 mb-2'>{title}</h3>
        <p>{lead}</p>
      </div>
    </div>
  )
}

FeatureBox.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string
}

FeatureBox.defaultProps = {
  title: '',
  subtitle: '',
  icon: ''
}

export default FeatureBox
